#IBEProgressBarWrapper{
	margin-bottom: 20px;
}
#result-vmb .IBESearchResult{
	width:100% !important;
}
.IBESRMain{
	width: 100%;
	overflow:visible;
}
.Box .BoxContent{
	border-bottom: 1px;
}
#IBESelectAirPortRegion{
	z-index:9999999;
}
.FlightContent{
	border:0px;
}
#result-vmb .SearchParamDomestic, #result-vmb .SearchParamGlobal{
	padding: 10px 0px;
	height: 73px;
}
$border-thickness: 1.5px;
.flight{
	border: $border-thickness solid #eaeaea;
	border-bottom:0px;
	*{
		transition: .5s !important;
	}
	.item{
		.info{
			padding: 5px 10px;
			position: relative;
			color: #363434;
			.info_flight{
				width: 80%;
				display: block;
				margin: auto;
			}
			.depart,.arrive{
				font-weight: bold;
				font-size: 15px;
				text-transform: uppercase;
				padding: 10px;
				font-family: 'Quicksand', sans-serif !important;
			}
			.d_time i, .a_time i{
			    text-align: center;
			    border-radius: 50%;
			    color: #48C3E2;
			    border-color: #48C3E2;
			    border: 1.5px solid;
			    width: 45px;
			    height: 45px;
			    display: inline-block;
			    line-height: 45px;
			    font-weight: bold;
			    font-size: 14px;
			    font-style:normal;
			}
			.f_info{
				position: relative;
				width: 250px;
				height: 33px;
				display: inline-block;
				.logo{
					display: inline-block;
					position: absolute;
					left: 47%;
					top: -2px;
					img{
						width: 30px !important;
						padding: 0px;
						z-index: 1;
					}
				}
				.logo.dif{
					left: 41%;
					top: 3px;
					img{
						width: 50px !important;
					}
				}
				.flight_info{
					position: absolute;
					bottom: -15px;
					left: 45%;
				}
				hr{
					margin:0px;
					border-top: 2px dashed #48C3E2;
					position: absolute;
					width: 97%;
					bottom: 4px;
					left: 1%;
					overflow: visible;
					font:normal normal normal 14px/1 FontAwesome;
				}
				hr:after{
					content: "\f105";
					color: #3ac2e2;
				    /* display: block; */
				    overflow: visible;
				    position: absolute;
				    right: -5px;
				    font-size: 25px;
				    top: -14px;
				}
			}
		}
		.price{
			border-left: $border-thickness solid #eaeaea;
			padding: 12px 10px;
			position: relative;
			.p{
				font-size: 20px;
				color: #DD3732;
				// display: block;
				text-align: center;
				margin-bottom: 10px;
			    padding-left: 15px;
			    line-height: 35px;
			}
			.dv{
				// display: block;
				text-align: center;
				position: absolute;
				top: 23px;
			    right: 0px;
				a{
					color: #fff;
					background: #48C3E2;
					padding: 6px 11px;
					border-radius: 10px;
				}
			}
		}
	}

}
.flight:last-child{
	border-bottom:$border-thickness solid #eaeaea !important;
}
.flight:hover{
		background:#e2f9ff;
}
@media(max-width: 768px){
	.box-search-blog{
		display: none;
	}
	// .flight {
	// 	.item{
	// 		.info{
	// 			padding: 25px 10px;
	// 			position: relative;
	// 			color: #363434;
	// 			.info_flight{
	// 				width: 100%;
	// 				display: block;
	// 				margin: auto;
	// 			}
	// 			.depart,.arrive{
	// 				font-size: 15px;
	// 			}
	// 			.depart{
	// 			    position: absolute;
	// 			    text-align: left;
	// 			    top: 71px;
	// 			    left: 0px;
	// 			    font-size: 15px;
	// 			}
	// 			.arrive{
	// 				top: 71px;
	// 				position: absolute;
	// 				right: 15px;
	// 				font-size: 15px;
	// 			}
	// 			.d_time{
	// 				position: absolute;
	// 				left: 23px;
	// 				top: 24px;
	// 			}
	// 			.a_time{
	// 				position: absolute;
	// 				right: 23px;
	// 				top: 24px;
	// 			}
	// 			.d_time i, .a_time i{
	// 			}
	// 			.f_info{
	// 				width: 100%;
	// 				.logo{
	// 					img{

	// 					}
	// 				}
	// 				.flight_info{

	// 				}
	// 				hr{
	// 					position:relative;
	// 					width: 50%;
	// 				    margin-top: 30px;
	// 				    margin-left: auto;
	// 				    margin-right: auto;
	// 				    display: block;
	// 				    font:normal normal normal 14px/1 FontAwesome;
	// 				    overflow: visible;
	// 				}
	// 				hr:after{
	// 					content: "\f105";
	// 					color: #3ac2e2;
	// 				    /* display: block; */
	// 				    overflow: visible;
	// 				    position: absolute;
	// 				    right: -4px;
	// 				    font-size: 25px;
	// 				    top: -14.5px;
	// 				}
	// 			}
	// 		}
	// 		.price{
	// 			border:0px;
	// 			padding: 14px 10px;
	// 			text-align: center;
	// 			.p{
	// 				font-size: 21px;
	// 				color: #DD3732;
	// 				display: inline-block;
	// 				// text-align: center;
	// 				// margin-bottom: 10px;
	// 			}
	// 			.dv{
	// 				display: inline-block;
	// 				// text-align: center;

	// 				a{

	// 				}
	// 			}
	// 		}
	// 		.col-md-3{
	// 			margin-top: 36px;

	// 		}
	// 	}
	// }
	.flight {
		.item{
			position: relative;
			.info{
				padding: 5px;
				position: relative;
				color: #363434;
				.info_flight{
					position: relative;
					width: 100%;
					display: block;
					margin: auto;
				}
				.depart,.arrive{
					font-size: 10px;
					padding:0px;
				}
				.depart{
				    position: absolute;
				    text-align: left;
				    top: 40px;
				    left: 1%;
				}
				.arrive{
					top: 40px;
					left: 42%;
					position: absolute;
				}
				.d_time{
					position: absolute;
					left: 1%;
					top: 7px;
				}
				.a_time{
					position: absolute;
					left: 43%;
					top: 7px;
				}
				.d_time i, .a_time i{
				    width: 30px;
    				height: 30px;
    				line-height: 29px;
    				font-size: 9px;
				}
				.f_info{
					width: 100%;
					.logo{
						left: 23%;
						top: -5px;
						img{

						}
					}
					.logo.dif{
						left: 21%;
						top: 3px;
					}
					.flight_info{
						left: 24%;
						font-size: 10px;
						bottom: -10px;
					}
					hr{
						position:relative;
						width: 35%;
					    margin-top: 26px;
					    margin-left: 5%;
					    margin-right: auto;
					    display: block;
					    font:normal normal normal 14px/1 FontAwesome;
					    overflow: visible;
					}
					hr:after{
						content: "\f105";
						color: #3ac2e2;
					    /* display: block; */
					    overflow: visible;
					    position: absolute;
					    right: -4px;
					    font-size: 25px;
					    top: -14.5px;
					}
				}
			}
			.price{
				border:0px;
				padding: 8px 10px;
				text-align: center;
				.p{
					font-size: 17px;
					color: #DD3732;
					display: inline-block;
				    position: absolute;
				    top: -33px;
				    right: 20%;
					// text-align: center;
					// margin-bottom: 10px;
				}
				.dv{
					display: inline-block;
					position: absolute;
				    top: -26px;
				    right: 5%;
					// text-align: center;
					a{
						font-size: 11px;
					}
				}
			}
			.col-md-3{
				padding:0px !important;
			}
		}
	}

}

@media(max-width: 425px){
	.container{
		padding-left: 5px !important;
		padding-right: 5px !important;
	}
	// .flight {
	// 	.item{
	// 		.info{
	// 			.f_info{

	// 				hr{
	// 					position:relative;
	// 					width: 32%;
	// 				    margin-top: 30px;
	// 				    margin-left: auto;
	// 				    margin-right: auto;
	// 				    display: block;
	// 				    font:normal normal normal 14px/1 FontAwesome;
	// 				    overflow: visible;
	// 				}
	// 				hr:after{
	// 					content: "\f105";
	// 					color: #3ac2e2;
	// 				    /* display: block; */
	// 				    overflow: visible;
	// 				    position: absolute;
	// 				    right: -6px;
	// 				    font-size: 25px;
	// 				    top: -14.5px;
	// 				}
	// 			}
	// 		}

	// 	}
	// }
	.IBESearchResult.IBEMobile ul.IBEProgressBar li{
		margin-right: 0px;
		padding-right: 15px;
		a{
			font-size: 10px;
		}
	}
	.IBESearchResult.IBEMobile ul.IBEProgressBar li:last-child{
		padding:0px;
	}
	.flight {
		.item{
			position: relative;
			.info{
				padding: 1px;
				position: relative;
				color: #363434;
				.info_flight{
					position: relative;
					width: 100%;
					display: block;
					margin: auto;
				}
				.depart,.arrive{
					font-size: 10px;
					padding:0px;
				}
				.depart{
				    position: absolute;
				    text-align: left;
				    top: 40px;
				    left: 1%;
				}
				.arrive{
					top: 40px;
					left: 43%;
					position: absolute;
				}
				.d_time{
					position: absolute;
					left: 1%;
					top: 7px;
				}
				.a_time{
					position: absolute;
					left: 43%;
					top: 7px;
				}
				.d_time i, .a_time i{
				    width: 30px;
    				height: 30px;
    				line-height: 29px;
    				font-size: 9px;
				}
				.f_info{
					width: 100%;
					.logo{
						left: 23%;
						top: -5px;
						img{

						}
					}
					.flight_info{
						left: 24%;
						font-size: 10px;
						bottom: -10px;
					}
					hr{
						position:relative;
						width: 30%;
					    margin-top: 26px;
					    margin-left: 11%;
					    margin-right: auto;
					    display: block;
					    font:normal normal normal 14px/1 FontAwesome;
					    overflow: visible;
					}
					hr:after{
						content: "\f105";
						color: #3ac2e2;
					    /* display: block; */
					    overflow: visible;
					    position: absolute;
					    right: -4px;
					    font-size: 25px;
					    top: -14.5px;
					}
				}
			}
			.price{
				border:0px;
				padding: 8px 10px;
				text-align: center;
				.p{
					font-size: 12px;
					color: #DD3732;
					display: inline-block;
				    position: absolute;
				    top: -32px;
				    right: 19%;
					// text-align: center;
					// margin-bottom: 10px;
				}
				.dv{
					display: inline-block;
					position: absolute;
				    top: -24px;
				    right: 1%;
					// text-align: center;
					a{
						font-size: 8px;
						padding: 6px 9px;
					}
				}
			}
			.col-md-3{
				padding:0px !important;
			}
		}
	}
}